import React, { Component } from "react";
import logo from "./assets/logo.png";
import "./App.css";
import {signIn} from './services/Auth/Auth.js'

class App extends Component {
    

  handleSubmit = async e => {
    e.preventDefault();
    console.log(e.target.email.value);

    if (!e.target.email.value) {
      alert("Email is required");
    } else if (!e.target.email.value) {
      alert("Valid email is required");
    } else if (!e.target.password.value) {
      alert("Password is required");    
    } else {
        try {
            const response = await signIn(e.target.email.value, e.target.password.value)
          } catch (error) {
                alert("Wrong email or password combination");
          } finally {
          }

    }
  };

  handleClick = e => {
    e.preventDefault();

    alert("Goes to registration page");
  };

  render() {
    return (
      <div className="App">
        <img src={logo} className="logo" alt="Logo" />
        <form className="form" onSubmit={this.handleSubmit}>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input type="email" name="email" />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input type="password" name="password" />
          </div>
          <button className="primary">Login</button>
        </form>
        
      </div>
    );
  }
}

export default App;
