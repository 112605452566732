import axios, { AxiosRequestConfig } from 'axios'

const instance = axios.create({
  baseURL: window.location.origin + '/api',
  timeout: 10000,
})

instance.interceptors.response.use(null, (error) => {
  if (error.response.status === 401) {
  }
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500

  if (!expectedError) {
    alert('An unexpected error occurred.')
  }

  return Promise.reject(error)
})

function setAuthToken(token) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

const get = async (url, config) => (await instance.get(url, config)).data
const post = async (url, data, config) =>
  (await instance.post(url, data, config)).data
const put = async (url, data, config) =>
  (await instance.put(url, data, config)).data 
const patch = async (url, data, config) =>
  (await instance.patch(url, data, config)).data
const Delete = async (url, config) => (await instance.delete(url, config)).data

export default {
  setAuthToken,
  get,
  post,
  put,
  patch,
  delete: Delete,
  postForm: instance.postForm,
}
