import jwtDecode from 'jwt-decode'
import http from '../httpService'

export const signIn = async (email, password) => {
  const res = await http.post('/login', {
    email,
    password,
  })

  const loggedUser = setToken(res.token)
  return Promise.resolve(loggedUser)
}

export const setToken = (token) => {
  localStorage.setItem('token', token)
  http.setAuthToken(token)
  return jwtDecode(token)
}

export const signOut = () => {
  localStorage.removeItem('token')
}
